import intlTelInput from 'intl-tel-input';

function openAccordion(el) {
  const activeItem = document.querySelector('.accordion-item.active')
  const tagretChild = el.querySelector('.accordion-item-description')
  const fontSize = parseInt(window.getComputedStyle(tagretChild).fontSize, 10)
  const lineHeight = parseInt(window.getComputedStyle(tagretChild).lineHeight, 10)

  const symbolsInRow = tagretChild.clientWidth/fontSize
  const textLength = tagretChild.textContent.length

  if(activeItem) {
    activeItem.classList.remove('active')
    activeItem.querySelector('.accordion-item-description').style.height = 0
  }

  if (el !== activeItem || !activeItem) {
    el.classList.add('active')
    tagretChild.style.height = `${(textLength / symbolsInRow + 2) * lineHeight / 2}px`
  }
}

function drawContactModal() {
  const partnerMmodal = document.getElementById('partner-modal')
  const contactModal = document.getElementById('contact');

  hideModal(partnerMmodal)
  showModal('contact')
  setTimeout(() => {
    hideModal(contactModal)
  }, 3500)
}

function showModal(id) {
  const modal = document.getElementById(id);
  const body = document.querySelector('body')

  body.style.overflow = 'hidden'
  modal.classList.add('modal-active');

  setTimeout(() => {
    modal.querySelector('.modal__input-field').focus()
  }, 300)
}

function hideModal(modal) {
  const body = document.querySelector('body')
  body.style.overflow = 'auto'
  modal.classList.remove('modal-active')
}

const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function validateEmail(email) {
  if (!email) { return true; }
  return EMAIL_REGEXP.test(String(email).toLowerCase());
}

function validatePresence(val) { return !!val; }

const formValidationErrors = {
  name: { validate: validatePresence },
  salon_name: { validate: validatePresence },
  phone_number: { validate: validatePresence },
  phone_country_code: { validate: validatePresence },
  email: { validate: validateEmail },
  message: { validate: validateEmail },
};

const formFields = Object.keys(formValidationErrors);

let isFormSubmitted = false;
function submitForm(e, { dialCode }) {
  e.preventDefault();
  if (isFormSubmitted) { return; }
  const request = new XMLHttpRequest();
  request.open('POST', API_SERVER + '/v10/owner/registration_request', true);
  request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
  const params = formFields.reduce((acc, el) => {
    if (el === 'phone_country_code') {
      acc[el] = `+${dialCode}`
    } else if (el === 'phone_number') {
      acc[el] = document.getElementById(el).value.replace(dialCode, '')
    } else {
      acc[el] = document.getElementById(el).value;
    }

    return acc;
  }, {});

  request.onload = function onLoad() {
    if (this.status === 422) {
      drawServerErrors(JSON.parse(this.response));
    }
    if (this.status === 200){
      drawContactModal()
    }
  };
  request.send(JSON.stringify({ registration_request: params }));
}

function drawServerErrors(error) {
  console.log(error)
  Object.keys(error.field_errors).forEach((fieldName) => {
    const span = document.createElement('span');
    span.classList.add('form-field-error');
    span.textContent = error.field_errors[fieldName];
    console.log(document.getElementById(fieldName))
    document.getElementById(fieldName).closest('.modal__input').appendChild(span);
  });
}

(function() {

  // Accordion

  document.querySelectorAll('.accordion-item').forEach((el) => {
    el.addEventListener('click', () => {
      openAccordion(el)
    })

    el.addEventListener('keyup', (event) => {
      if (event.keyCode === 13) {
        openAccordion(el)
      }
   })
  })

  // Modal

  document.querySelectorAll('.modal').forEach((el) => {
    setTimeout(() => {
      el.style.display = 'block'
    }, 1)
  })

  document.querySelectorAll('.modal__close-btn').forEach((el) => {
    el.addEventListener('click', (event) => {
      hideModal(event.target.closest('.modal'))
    })
  })

  document.querySelectorAll('.modal__overlay').forEach((el) => {
    el.addEventListener('click', (event) => {
      if (event.target.classList.contains('modal__overlay')) {
        hideModal(event.target.closest('.modal'))
      }
    })
  })

  document.querySelector('.business-list__btn').addEventListener('click', () => {
    showModal('partner-modal')
  })

  document.querySelectorAll('.modal__input-field').forEach((el) => {
    el.addEventListener('input', () => {
      if (!el.nextElementSibling) return

      if (el.value) {
        el.nextElementSibling.classList.add('modal__input-label-active')
      } else {
        el.nextElementSibling.classList.remove('modal__input-label-active')
      }
    })
  })

  const textarea = document.querySelector('textarea')
  textarea.style.height = textarea.setAttribute('style', 'height: ' + textarea.scrollHeight + 'px');
  textarea.classList.add('auto');

  textarea.addEventListener('input', () => {
    textarea.style.height = 'auto';
    textarea.style.height = (textarea.scrollHeight) + 'px';
  });

  const input = document.getElementById('phone_number');
  const iti = intlTelInput(input);

  const signupForm = document.getElementById('signup_form');
  if (signupForm) {
    signupForm.addEventListener('submit', (e) => {
      submitForm(e, iti.getSelectedCountryData())
    });
  }
}());
